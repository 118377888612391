.shg-box {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  /**
   * While `flex: 1` is enough here, we need to supply the rest
   * of the parameters (`1 auto`) to keep compatibility with IE11.
   * Otherwise, IE11 flex would break.
   */
  flex: 1 1 auto;
}

.shg-box > .shg-box-content {
  z-index: 4;
  position: relative;
  /**
   * This is part of an IE11 fallback to avoid flex introducing
   * huge amount of weird space on the bottom on the section element.
   */
  min-height: 1px;
}

.shg-box-vertical-align-wrapper, .shg-box-vertical-center-wrapper {
  display: flex;
  width: 100%;
}

.shg-box-vertical-align-top {
  justify-content: flex-start;
}

.shg-box-vertical-align-center, .shg-box-vertical-center {
  justify-content: center;
}

.shg-box-vertical-align-bottom {
  justify-content: flex-end;
}

.shg-box-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  pointer-events: none;
}

.shg-box-video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
}

#s-6b33c6cb-4edf-450d-a5ad-7116e83d1da4 {
  min-height: 50px;
}
@media (min-width: 768px) and (max-width: 991px){#s-6b33c6cb-4edf-450d-a5ad-7116e83d1da4 {
  display: none;
}
#s-6b33c6cb-4edf-450d-a5ad-7116e83d1da4, #wrap-s-6b33c6cb-4edf-450d-a5ad-7116e83d1da4 { display:none !important; }}@media (max-width: 767px){#s-6b33c6cb-4edf-450d-a5ad-7116e83d1da4 {
  display: none;
}
#s-6b33c6cb-4edf-450d-a5ad-7116e83d1da4, #wrap-s-6b33c6cb-4edf-450d-a5ad-7116e83d1da4 { display:none !important; }}







#s-6b33c6cb-4edf-450d-a5ad-7116e83d1da4 > .shg-box-overlay {
  background-color: #fff;
  opacity: 0;
}#s-6b33c6cb-4edf-450d-a5ad-7116e83d1da4.shg-box.shg-c {
  justify-content: center;
}

.shogun-image-container {
  position: relative;
}

.shogun-image-container.shg-align-left {
  text-align: left;
}

.shogun-image-container.shg-align-center {
  text-align: center;
}

.shogun-image-container.shg-align-right {
  text-align: right;
}

.shogun-image-linked {
  cursor: pointer;
}

.shogun-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.shogun-image-overlay.shg-top-left {
  align-items: flex-start;
  justify-content: flex-start;
}

.shogun-image-overlay.shg-top-center {
  align-items: flex-start;
  justify-content: center;
}

.shogun-image-overlay.shg-top-right {
  align-items: flex-start;
  justify-content: flex-end;
}

.shogun-image-overlay.shg-middle-left {
  align-items: center;
  justify-content: flex-start;
}

.shogun-image-overlay.shg-middle-center {
  align-items: center;
  justify-content: center;
}

.shogun-image-overlay.shg-middle-right {
  align-items: center;
  justify-content: flex-end;
}

.shogun-image-overlay.shg-bottom-left {
  align-items: flex-end;
  justify-content: flex-start;
}

.shogun-image-overlay.shg-bottom-center {
  align-items: flex-end;
  justify-content: center;
}

.shogun-image-overlay.shg-bottom-right {
  align-items: flex-end;
  justify-content: flex-end;
}

.shogun-image-overlay p {
  margin: 0;
  padding: 0;
  line-height: normal;
}

.shogun-image-cover {
  -o-object-fit: cover;
     object-fit: cover;
  font-family: "object-fit: cover;";
  width: 100%;
}

.shogun-image-contain {
  font-family: "object-fit: contain;";
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
}

.shogun-image-link {
  display: block;
  min-height: inherit;
  max-height: inherit;
}

img.shogun-image {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.shogun-image-content {
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}

.shogun-image-content-linked, .shogun-image-content-not-linked {
  pointer-events: none;
}

.shogun-image-content-not-linked > div {
  pointer-events: auto;
}

.shogun-image-content-linked a,
.shogun-image-content-linked button,
.shogun-image-content-linked iframe,
.shogun-image-content-linked .shg-box-linked {
  pointer-events: auto;
}

.shogun-image-content > div {
  width: 100%;
}

.shogun-image-content-top {
  align-items: flex-start;
}

.shogun-image-content-center {
  align-items: center;
}

.shogun-image-content-bottom {
  align-items: flex-end;
}
#s-1adc716d-ea50-4435-847e-d5d709d589bf {
  text-align: center;
opacity: 1;
}
@media (min-width: 768px) and (max-width: 991px){#s-1adc716d-ea50-4435-847e-d5d709d589bf {
  display: none;
}
#s-1adc716d-ea50-4435-847e-d5d709d589bf, #wrap-s-1adc716d-ea50-4435-847e-d5d709d589bf { display:none !important; }}@media (max-width: 767px){#s-1adc716d-ea50-4435-847e-d5d709d589bf {
  display: none;
}
#s-1adc716d-ea50-4435-847e-d5d709d589bf, #wrap-s-1adc716d-ea50-4435-847e-d5d709d589bf { display:none !important; }}




#s-1adc716d-ea50-4435-847e-d5d709d589bf .shogun-image-content {
  
    align-items: center;
  
}

.shg-row {
  display: flex;
  flex-wrap: wrap;
  min-height: inherit;
  max-height: inherit;
justify-content: space-between;
}

.shg-row > * {
  min-height: inherit;
  max-height: inherit;
}

.shg-c-xs-1,
.shg-c-xs-2,
.shg-c-xs-3,
.shg-c-xs-4,
.shg-c-xs-5,
.shg-c-xs-6,
.shg-c-xs-7,
.shg-c-xs-8,
.shg-c-xs-9,
.shg-c-xs-10,
.shg-c-xs-11,
.shg-c-xs-12,
.shg-c-sm-1,
.shg-c-sm-2,
.shg-c-sm-3,
.shg-c-sm-4,
.shg-c-sm-5,
.shg-c-sm-6,
.shg-c-sm-7,
.shg-c-sm-8,
.shg-c-sm-9,
.shg-c-sm-10,
.shg-c-sm-11,
.shg-c-sm-12,
.shg-c-md-1,
.shg-c-md-2,
.shg-c-md-3,
.shg-c-md-4,
.shg-c-md-5,
.shg-c-md-6,
.shg-c-md-7,
.shg-c-md-8,
.shg-c-md-9,
.shg-c-md-10,
.shg-c-md-11,
.shg-c-md-12,
.shg-c-lg-1,
.shg-c-lg-2,
.shg-c-lg-3,
.shg-c-lg-4,
.shg-c-lg-5,
.shg-c-lg-6,
.shg-c-lg-7,
.shg-c-lg-8,
.shg-c-lg-9,
.shg-c-lg-10,
.shg-c-lg-11,
.shg-c-lg-12 {
  position: relative;
}

@media (min-width: 0px) {
[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-xs-12 {
  width: 100%;
}

}

@media (min-width: 768px) {
[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-sm-1 {
  width: calc(8.333333333333334% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-sm-2 {
  width: calc(16.666666666666668% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-sm-3 {
  width: calc(25.0% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-sm-4 {
  width: calc(33.333333333333336% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-sm-5 {
  width: calc(41.66666666666667% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-sm-6 {
  width: calc(50.0% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-sm-7 {
  width: calc(58.333333333333336% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-sm-8 {
  width: calc(66.66666666666667% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-sm-9 {
  width: calc(75.0% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-sm-10 {
  width: calc(83.33333333333334% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-sm-11 {
  width: calc(91.66666666666667% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-sm-12 {
  width: calc(100.0% - 15.0px);
}

}

@media (min-width: 992px) {
[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-md-1 {
  width: calc(8.333333333333334% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-md-2 {
  width: calc(16.666666666666668% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-md-3 {
  width: calc(25.0% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-md-4 {
  width: calc(33.333333333333336% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-md-5 {
  width: calc(41.66666666666667% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-md-6 {
  width: calc(50.0% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-md-7 {
  width: calc(58.333333333333336% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-md-8 {
  width: calc(66.66666666666667% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-md-9 {
  width: calc(75.0% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-md-10 {
  width: calc(83.33333333333334% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-md-11 {
  width: calc(91.66666666666667% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-md-12 {
  width: calc(100.0% - 15.0px);
}

}

@media (min-width: 1200px) {
[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-lg-1 {
  width: calc(8.333333333333334% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-lg-2 {
  width: calc(16.666666666666668% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-lg-3 {
  width: calc(25.0% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-lg-4 {
  width: calc(33.333333333333336% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-lg-5 {
  width: calc(41.66666666666667% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-lg-6 {
  width: calc(50.0% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-lg-7 {
  width: calc(58.333333333333336% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-lg-8 {
  width: calc(66.66666666666667% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-lg-9 {
  width: calc(75.0% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-lg-10 {
  width: calc(83.33333333333334% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-lg-11 {
  width: calc(91.66666666666667% - 15.0px);
}

[id="s-38c0772d-8be0-45c3-9ddf-e541273a1c24"] > .shg-row > .shg-c-lg-12 {
  width: calc(100.0% - 15.0px);
}

}

.shogun-heading-component h1,
.shogun-heading-component h2,
.shogun-heading-component h3,
.shogun-heading-component h4,
.shogun-heading-component h5,
.shogun-heading-component h6 {
  margin: 0;
  padding: 0;
  display: block;
  color: #000;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}

.shogun-heading-component a {
  text-decoration: none !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}

#s-db09e24a-06b0-4d2a-9c30-4f24f59e2057 {
  padding-top: 10px;
padding-bottom: 10px;
text-align: center;
}
@media (min-width: 768px) and (max-width: 991px){#s-db09e24a-06b0-4d2a-9c30-4f24f59e2057 {
  display: none;
}
#s-db09e24a-06b0-4d2a-9c30-4f24f59e2057, #wrap-s-db09e24a-06b0-4d2a-9c30-4f24f59e2057 { display:none !important; }}@media (max-width: 767px){#s-db09e24a-06b0-4d2a-9c30-4f24f59e2057 {
  display: none;
}
#s-db09e24a-06b0-4d2a-9c30-4f24f59e2057, #wrap-s-db09e24a-06b0-4d2a-9c30-4f24f59e2057 { display:none !important; }}
#s-db09e24a-06b0-4d2a-9c30-4f24f59e2057 .shogun-heading-component h1 {
  color: rgba(255, 255, 255, 1);
  font-weight:   ;
  font-family: Made Canvas;
  font-style:   ;
  font-size: 32px;
  line-height: ;
  letter-spacing: ;
  text-align: center;
}



.shg-btn.shg-cse, .shg-btn.shg-cse:hover, .shg-btn.shg-cse:focus {
  color: #FFF;
}

.shg-btn {
  cursor: pointer;
  box-sizing: border-box;
}

.shg-btn.shg-btn-stretch {
  display: block;
}

.shg-btn:not(.shg-btn-stretch) {
  display: inline-block;
}

.shg-btn-wrapper.shg-align-left {
  text-align: left;
}

.shg-btn-wrapper.shg-align-center {
  text-align: center;
}

.shg-btn-wrapper.shg-align-right {
  text-align: right;
}

#s-8f63f163-5840-47f7-bd47-b2c80022afb4 {
  border-style: solid;
margin-top: 30px;
margin-bottom: 270px;
padding-top: 15px;
padding-left: 20px;
padding-bottom: 15px;
padding-right: 20px;
border-top-width: 1px;
border-left-width: 1px;
border-bottom-width: 1px;
border-right-width: 1px;
border-color: #2C2A34;
border-radius: 30px;
background-color: rgba(255, 255, 255, 1);
text-align: center;
text-decoration: none;
background-image: none;
hover-type: color;
color: rgba(0, 0, 0, 1);
background-position: undefined center;
}
#s-8f63f163-5840-47f7-bd47-b2c80022afb4:hover {border-style: solid !important;
border-top-width: 1px !important;
border-left-width: 1px !important;
border-bottom-width: 1px !important;
border-right-width: 1px !important;
border-color: #2C2A34 !important;
border-radius: 30px !important;
background-color: rgba(175, 163, 66, 1) !important;
text-decoration: none !important;
background-image: none !important;
hover-type: color !important;
color: rgba(255, 255, 255, 1) !important;}#s-8f63f163-5840-47f7-bd47-b2c80022afb4:active {background-color: #000000 !important;
text-decoration: none !important;}@media (min-width: 768px) and (max-width: 991px){#s-8f63f163-5840-47f7-bd47-b2c80022afb4 {
  display: none;
}
#s-8f63f163-5840-47f7-bd47-b2c80022afb4, #wrap-s-8f63f163-5840-47f7-bd47-b2c80022afb4 { display:none !important; }}@media (max-width: 767px){#s-8f63f163-5840-47f7-bd47-b2c80022afb4 {
  display: none;
}
#s-8f63f163-5840-47f7-bd47-b2c80022afb4, #wrap-s-8f63f163-5840-47f7-bd47-b2c80022afb4 { display:none !important; }}


#s-8f63f163-5840-47f7-bd47-b2c80022afb4.shg-btn {
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  
  
  font-family: futura;
  display:  inline-block ;
}

#s-d7c4c343-4b61-4bc9-aace-b94ff489fdd5 {
  background-size: cover;
min-height: 50px;
background-color: rgba(255, 255, 255, 1);
background-position: center center;
background-repeat: no-repeat;
}
@media (min-width: 768px) and (max-width: 991px){#s-d7c4c343-4b61-4bc9-aace-b94ff489fdd5 {
  
}
}@media (max-width: 767px){#s-d7c4c343-4b61-4bc9-aace-b94ff489fdd5 {
  
}
}







#s-d7c4c343-4b61-4bc9-aace-b94ff489fdd5 > .shg-box-overlay {
  background-color: #fff;
  opacity: 0;
}#s-d7c4c343-4b61-4bc9-aace-b94ff489fdd5.shg-box.shg-c {
  justify-content: center;
}

#s-706d70e4-7c7e-41ef-b5a5-aef7ff87247d {
  min-height: 50px;
}








#s-706d70e4-7c7e-41ef-b5a5-aef7ff87247d > .shg-box-overlay {
  background-color: #fff;
  opacity: 0;
}#s-706d70e4-7c7e-41ef-b5a5-aef7ff87247d.shg-box.shg-c {
  justify-content: center;
}

@media (min-width: 0px) {
[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-xs-12 {
  width: 100%;
}

}

@media (min-width: 768px) {
[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-sm-1 {
  width: calc(8.333333333333334% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-sm-2 {
  width: calc(16.666666666666668% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-sm-3 {
  width: calc(25.0% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-sm-4 {
  width: calc(33.333333333333336% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-sm-5 {
  width: calc(41.66666666666667% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-sm-6 {
  width: calc(50.0% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-sm-7 {
  width: calc(58.333333333333336% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-sm-8 {
  width: calc(66.66666666666667% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-sm-9 {
  width: calc(75.0% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-sm-10 {
  width: calc(83.33333333333334% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-sm-11 {
  width: calc(91.66666666666667% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-sm-12 {
  width: calc(100.0% - 0.0px);
}

}

@media (min-width: 992px) {
[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-md-1 {
  width: calc(8.333333333333334% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-md-2 {
  width: calc(16.666666666666668% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-md-3 {
  width: calc(25.0% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-md-4 {
  width: calc(33.333333333333336% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-md-5 {
  width: calc(41.66666666666667% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-md-6 {
  width: calc(50.0% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-md-7 {
  width: calc(58.333333333333336% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-md-8 {
  width: calc(66.66666666666667% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-md-9 {
  width: calc(75.0% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-md-10 {
  width: calc(83.33333333333334% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-md-11 {
  width: calc(91.66666666666667% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-md-12 {
  width: calc(100.0% - 0.0px);
}

}

@media (min-width: 1200px) {
[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-lg-1 {
  width: calc(8.333333333333334% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-lg-2 {
  width: calc(16.666666666666668% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-lg-3 {
  width: calc(25.0% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-lg-4 {
  width: calc(33.333333333333336% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-lg-5 {
  width: calc(41.66666666666667% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-lg-6 {
  width: calc(50.0% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-lg-7 {
  width: calc(58.333333333333336% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-lg-8 {
  width: calc(66.66666666666667% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-lg-9 {
  width: calc(75.0% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-lg-10 {
  width: calc(83.33333333333334% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-lg-11 {
  width: calc(91.66666666666667% - 0.0px);
}

[id="s-44c70a48-b98b-4439-94a9-e6b11da71489"] > .shg-row > .shg-c-lg-12 {
  width: calc(100.0% - 0.0px);
}

}

#s-a0086171-5446-462c-bdf6-a045e2d931ff {
  margin-left: 45px;
margin-right: 45px;
padding-top: 10px;
padding-bottom: 10px;
text-align: center;
}
@media (min-width: 768px) and (max-width: 991px){#s-a0086171-5446-462c-bdf6-a045e2d931ff {
  display: none;
}
#s-a0086171-5446-462c-bdf6-a045e2d931ff, #wrap-s-a0086171-5446-462c-bdf6-a045e2d931ff { display:none !important; }}@media (max-width: 767px){#s-a0086171-5446-462c-bdf6-a045e2d931ff {
  display: none;
}
#s-a0086171-5446-462c-bdf6-a045e2d931ff, #wrap-s-a0086171-5446-462c-bdf6-a045e2d931ff { display:none !important; }}
#s-a0086171-5446-462c-bdf6-a045e2d931ff .shogun-heading-component h2 {
  color: rgba(0, 0, 0, 1);
  font-weight:   ;
  font-family: Made Canvas;
  font-style:   ;
  font-size: 32px;
  line-height: ;
  letter-spacing: ;
  text-align: center;
}



.shg-rich-text {
  overflow-wrap: break-word;
}

.shg-rich-text img {
  margin: 0 20px;
}

@media (max-width: 768px) {
  .shg-rich-text img {
    display: block;
    float: none !important;
    margin: 0 auto;
  }
}

.shg-default-text-content *:first-child {
  margin-top: 0;
}

.shg-default-text-content {
  text-align: left;
}

.shg-default-text-content p,
.shg-default-text-content h1,
.shg-default-text-content h2,
.shg-default-text-content h3,
.shg-default-text-content h4,
.shg-default-text-content h5,
.shg-default-text-content h6,
.shg-default-text-content address,
.shg-default-text-content pre,
.shg-default-text-content div,
.shg-default-text-content ol,
.shg-default-text-content ul {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: #000000;
  font-family: inherit;
  font-style: normal;
  margin-bottom: 0;
  padding: 0;
  text-align: left;
  text-transform: none;
}

.shg-default-text-content a {
  background-color: inherit;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-style: inherit;
  text-decoration: underline;
  text-transform: inherit;
}

.shg-default-text-content strong,
.shg-default-text-content em {
  background-color: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-align: inherit;
  text-transform: inherit;
}

.shg-default-text-content em {
  font-weight: inherit;
}

.shg-default-text-content strong {
  font-style: inherit;
  font-weight: 700;
}

/* https://stackoverflow.com/a/16094931/3696652 */
.shg-default-text-content ::-moz-selection, .shg-default-text-content *::-moz-selection {
  background: #accef7;
}
.shg-default-text-content ::selection,
.shg-default-text-content *::selection {
  background: #accef7;
}

.shg-default-text-content p {
  font-size: 1em;
  font-weight: normal;
  letter-spacing: -0.005em;
  line-height: 1.714;
  margin-top: 0.6em;
}

.shg-default-text-content h1 {
  font-size: 1.714em;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 1.166;
  margin-top: 0.67em;
}

.shg-default-text-content h2 {
  font-size: 1.43em;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 1.2;
  margin-top: 0.83em;
}

.shg-default-text-content h3 {
  font-size: 1.142em;
  font-weight: 500;
  letter-spacing: -0.008em;
  line-height: 1.5;
  margin-top: 1em;
}

.shg-default-text-content h4 {
  font-size: 1em;
  font-weight: 600;
  letter-spacing: -0.006em;
  line-height: 1.428;
  margin-top: 1.33em;
}

.shg-default-text-content h5 {
  font-size: 0.857em;
  font-weight: 600;
  letter-spacing: -0.003em;
  line-height: 1.333;
  margin-top: 1.43em;
}

.shg-default-text-content h6 {
  font-size: 0.785em;
  font-weight: 600;
  letter-spacing: -0.003em;
  line-height: 1.454;
  margin-top: 1.42em;
  text-transform: uppercase;
}

.shg-default-text-content ul {
  list-style: disc;
}

.shg-default-text-content ol {
  list-style: decimal;
}

.shg-default-text-content ul,
.shg-default-text-content ol {
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  margin-bottom: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  margin-top: 0;
  -webkit-padding-start: 40px;
          padding-inline-start: 40px;
}

.shg-default-text-content li {
  font-size: 1em;
  font-weight: normal;
  letter-spacing: -0.005em;
  line-height: 1;
  list-style: inherit;
  margin-top: 0.67em;
}

.shg-default-text-content pre {
  font-family: monospace;
  font-size: 1em;
  font-weight: normal;
  letter-spacing: -0.005em;
  line-height: 1.714;
  margin-top: 1em;
  white-space: pre-wrap;
  word-break: normal;
}

.shg-default-text-content address {
  font-size: 1em;
  font-style: italic;
  font-weight: normal;
  letter-spacing: -0.005em;
  line-height: 1.714;
  margin-top: 0;
}

.shg-default-text-content div {
  font-size: 1em;
  font-weight: normal;
  letter-spacing: -0.005em;
  line-height: 1.714;
  margin-bottom: 0;
  margin-top: 0.67em;
}

#s-e62d6a4e-1b2a-43e7-8ec5-6ee1fb58b0c4 {
  margin-left: 105px;
margin-right: 105px;
}
@media (min-width: 768px) and (max-width: 991px){#s-e62d6a4e-1b2a-43e7-8ec5-6ee1fb58b0c4 {
  display: none;
}
#s-e62d6a4e-1b2a-43e7-8ec5-6ee1fb58b0c4, #wrap-s-e62d6a4e-1b2a-43e7-8ec5-6ee1fb58b0c4 { display:none !important; }}@media (max-width: 767px){#s-e62d6a4e-1b2a-43e7-8ec5-6ee1fb58b0c4 {
  display: none;
}
#s-e62d6a4e-1b2a-43e7-8ec5-6ee1fb58b0c4, #wrap-s-e62d6a4e-1b2a-43e7-8ec5-6ee1fb58b0c4 { display:none !important; }}
#s-122e647a-85b7-46be-9d2e-c621f651b725 {
  border-style: solid;
margin-top: 30px;
margin-bottom: 30px;
padding-top: 15px;
padding-left: 20px;
padding-bottom: 15px;
padding-right: 20px;
border-top-width: 1px;
border-left-width: 1px;
border-bottom-width: 1px;
border-right-width: 1px;
border-color: #2C2A34;
border-radius: 30px;
background-color: rgba(175, 163, 66, 1);
text-align: center;
text-decoration: none;
background-image: none;
hover-type: color;
color: rgba(255, 255, 255, 1);
}
#s-122e647a-85b7-46be-9d2e-c621f651b725:hover {border-style: solid !important;
border-top-width: 1px !important;
border-left-width: 1px !important;
border-bottom-width: 1px !important;
border-right-width: 1px !important;
border-color: #2C2A34 !important;
border-radius: 30px !important;
background-color: rgba(90, 100, 74, 1) !important;
text-decoration: none !important;
background-image: none !important;
hover-type: color !important;
color: rgba(255, 255, 255, 1) !important;}#s-122e647a-85b7-46be-9d2e-c621f651b725:active {background-color: #000000 !important;
text-decoration: none !important;}@media (min-width: 768px) and (max-width: 991px){#s-122e647a-85b7-46be-9d2e-c621f651b725 {
  display: none;
}
#s-122e647a-85b7-46be-9d2e-c621f651b725, #wrap-s-122e647a-85b7-46be-9d2e-c621f651b725 { display:none !important; }}@media (max-width: 767px){#s-122e647a-85b7-46be-9d2e-c621f651b725 {
  display: none;
}
#s-122e647a-85b7-46be-9d2e-c621f651b725, #wrap-s-122e647a-85b7-46be-9d2e-c621f651b725 { display:none !important; }}


#s-122e647a-85b7-46be-9d2e-c621f651b725.shg-btn {
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  
  
  font-family: futura;
  display:  inline-block ;
}

@media (min-width: 0px) {
[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-xs-12 {
  width: 100%;
}

}

@media (min-width: 768px) {
[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-sm-1 {
  width: calc(8.333333333333334% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-sm-2 {
  width: calc(16.666666666666668% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-sm-3 {
  width: calc(25.0% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-sm-4 {
  width: calc(33.333333333333336% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-sm-5 {
  width: calc(41.66666666666667% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-sm-6 {
  width: calc(50.0% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-sm-7 {
  width: calc(58.333333333333336% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-sm-8 {
  width: calc(66.66666666666667% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-sm-9 {
  width: calc(75.0% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-sm-10 {
  width: calc(83.33333333333334% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-sm-11 {
  width: calc(91.66666666666667% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-sm-12 {
  width: calc(100.0% - 0.0px);
}

}

@media (min-width: 992px) {
[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-md-1 {
  width: calc(8.333333333333334% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-md-2 {
  width: calc(16.666666666666668% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-md-3 {
  width: calc(25.0% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-md-4 {
  width: calc(33.333333333333336% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-md-5 {
  width: calc(41.66666666666667% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-md-6 {
  width: calc(50.0% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-md-7 {
  width: calc(58.333333333333336% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-md-8 {
  width: calc(66.66666666666667% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-md-9 {
  width: calc(75.0% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-md-10 {
  width: calc(83.33333333333334% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-md-11 {
  width: calc(91.66666666666667% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-md-12 {
  width: calc(100.0% - 0.0px);
}

}

@media (min-width: 1200px) {
[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-lg-1 {
  width: calc(8.333333333333334% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-lg-2 {
  width: calc(16.666666666666668% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-lg-3 {
  width: calc(25.0% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-lg-4 {
  width: calc(33.333333333333336% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-lg-5 {
  width: calc(41.66666666666667% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-lg-6 {
  width: calc(50.0% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-lg-7 {
  width: calc(58.333333333333336% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-lg-8 {
  width: calc(66.66666666666667% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-lg-9 {
  width: calc(75.0% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-lg-10 {
  width: calc(83.33333333333334% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-lg-11 {
  width: calc(91.66666666666667% - 0.0px);
}

[id="s-ea13b10f-b944-408b-8952-b9b24da3d00e"] > .shg-row > .shg-c-lg-12 {
  width: calc(100.0% - 0.0px);
}

}

#s-36d17b1f-c9dd-45b3-a48f-c79023923ba4 {
  text-align: center;
}




  #s-36d17b1f-c9dd-45b3-a48f-c79023923ba4 img.shogun-image {
    
    width: px;
  }


#s-36d17b1f-c9dd-45b3-a48f-c79023923ba4 .shogun-image-content {
  
    align-items: center;
  
}

#s-d4112d0c-5c13-4d31-81a5-6fc9bbea1918 {
  text-align: center;
}




  #s-d4112d0c-5c13-4d31-81a5-6fc9bbea1918 img.shogun-image {
    
    width: px;
  }


#s-d4112d0c-5c13-4d31-81a5-6fc9bbea1918 .shogun-image-content {
  
    align-items: center;
  
}

#s-a8c67188-c904-4681-b593-f79149cbe1c7 {
  text-align: center;
}




  #s-a8c67188-c904-4681-b593-f79149cbe1c7 img.shogun-image {
    
    width: px;
  }


#s-a8c67188-c904-4681-b593-f79149cbe1c7 .shogun-image-content {
  
    align-items: center;
  
}

#s-32d48161-48ea-4b87-928c-04b78d235673 {
  text-align: center;
}




  #s-32d48161-48ea-4b87-928c-04b78d235673 img.shogun-image {
    
    width: px;
  }


#s-32d48161-48ea-4b87-928c-04b78d235673 .shogun-image-content {
  
    align-items: center;
  
}

#s-e74b995d-d10f-4ed9-8231-1701256b7cc2 {
  min-height: 50px;
}
@media (min-width: 768px) and (max-width: 991px){#s-e74b995d-d10f-4ed9-8231-1701256b7cc2 {
  
}
}@media (max-width: 767px){#s-e74b995d-d10f-4ed9-8231-1701256b7cc2 {
  
}
}







#s-e74b995d-d10f-4ed9-8231-1701256b7cc2 > .shg-box-overlay {
  background-color: #fff;
  opacity: 0;
}#s-e74b995d-d10f-4ed9-8231-1701256b7cc2.shg-box.shg-c {
  justify-content: center;
}

#s-e364b77c-95db-4054-9fe8-fe0c17dfee30 {
  min-height: 50px;
}
@media (min-width: 768px) and (max-width: 991px){#s-e364b77c-95db-4054-9fe8-fe0c17dfee30 {
  display: none;
}
#s-e364b77c-95db-4054-9fe8-fe0c17dfee30, #wrap-s-e364b77c-95db-4054-9fe8-fe0c17dfee30 { display:none !important; }}@media (max-width: 767px){#s-e364b77c-95db-4054-9fe8-fe0c17dfee30 {
  display: none;
}
#s-e364b77c-95db-4054-9fe8-fe0c17dfee30, #wrap-s-e364b77c-95db-4054-9fe8-fe0c17dfee30 { display:none !important; }}







#s-e364b77c-95db-4054-9fe8-fe0c17dfee30 > .shg-box-overlay {
  background-color: #fff;
  opacity: 0;
}#s-e364b77c-95db-4054-9fe8-fe0c17dfee30.shg-box.shg-c {
  justify-content: center;
}

#s-77d7cfc9-1e58-4a8f-b7eb-28448cacb7f9 {
  padding-top: 10px;
padding-bottom: 10px;
text-align: center;
}
@media (min-width: 768px) and (max-width: 991px){#s-77d7cfc9-1e58-4a8f-b7eb-28448cacb7f9 {
  display: none;
}
#s-77d7cfc9-1e58-4a8f-b7eb-28448cacb7f9, #wrap-s-77d7cfc9-1e58-4a8f-b7eb-28448cacb7f9 { display:none !important; }}@media (max-width: 767px){#s-77d7cfc9-1e58-4a8f-b7eb-28448cacb7f9 {
  display: none;
}
#s-77d7cfc9-1e58-4a8f-b7eb-28448cacb7f9, #wrap-s-77d7cfc9-1e58-4a8f-b7eb-28448cacb7f9 { display:none !important; }}
#s-77d7cfc9-1e58-4a8f-b7eb-28448cacb7f9 .shogun-heading-component h2 {
  color: #000;
  font-weight:   ;
  font-family: ;
  font-style:   ;
  font-size: 18px;
  line-height: ;
  letter-spacing: ;
  text-align: center;
}



#s-3e716cbf-9086-4380-a242-6936dcd0b51c {
  margin-left: 75px;
margin-right: 75px;
}
@media (min-width: 768px) and (max-width: 991px){#s-3e716cbf-9086-4380-a242-6936dcd0b51c {
  display: none;
}
#s-3e716cbf-9086-4380-a242-6936dcd0b51c, #wrap-s-3e716cbf-9086-4380-a242-6936dcd0b51c { display:none !important; }}@media (max-width: 767px){#s-3e716cbf-9086-4380-a242-6936dcd0b51c {
  display: none;
}
#s-3e716cbf-9086-4380-a242-6936dcd0b51c, #wrap-s-3e716cbf-9086-4380-a242-6936dcd0b51c { display:none !important; }}
@media (min-width: 0px) {
[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-xs-1 {
  width: calc(8.333333333333334% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-xs-2 {
  width: calc(16.666666666666668% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-xs-3 {
  width: calc(25.0% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-xs-4 {
  width: calc(33.333333333333336% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-xs-5 {
  width: calc(41.66666666666667% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-xs-6 {
  width: calc(50.0% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-xs-7 {
  width: calc(58.333333333333336% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-xs-8 {
  width: calc(66.66666666666667% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-xs-9 {
  width: calc(75.0% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-xs-10 {
  width: calc(83.33333333333334% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-xs-11 {
  width: calc(91.66666666666667% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-xs-12 {
  width: calc(100.0% - 25.0px);
}

}

@media (min-width: 768px) {
[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-sm-1 {
  width: calc(8.333333333333334% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-sm-2 {
  width: calc(16.666666666666668% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-sm-3 {
  width: calc(25.0% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-sm-4 {
  width: calc(33.333333333333336% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-sm-5 {
  width: calc(41.66666666666667% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-sm-6 {
  width: calc(50.0% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-sm-7 {
  width: calc(58.333333333333336% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-sm-8 {
  width: calc(66.66666666666667% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-sm-9 {
  width: calc(75.0% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-sm-10 {
  width: calc(83.33333333333334% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-sm-11 {
  width: calc(91.66666666666667% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-sm-12 {
  width: calc(100.0% - 25.0px);
}

}

@media (min-width: 992px) {
[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-md-1 {
  width: calc(8.333333333333334% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-md-2 {
  width: calc(16.666666666666668% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-md-3 {
  width: calc(25.0% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-md-4 {
  width: calc(33.333333333333336% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-md-5 {
  width: calc(41.66666666666667% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-md-6 {
  width: calc(50.0% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-md-7 {
  width: calc(58.333333333333336% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-md-8 {
  width: calc(66.66666666666667% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-md-9 {
  width: calc(75.0% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-md-10 {
  width: calc(83.33333333333334% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-md-11 {
  width: calc(91.66666666666667% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-md-12 {
  width: calc(100.0% - 25.0px);
}

}

@media (min-width: 1200px) {
[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-lg-1 {
  width: calc(8.333333333333334% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-lg-2 {
  width: calc(16.666666666666668% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-lg-3 {
  width: calc(25.0% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-lg-4 {
  width: calc(33.333333333333336% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-lg-5 {
  width: calc(41.66666666666667% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-lg-6 {
  width: calc(50.0% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-lg-7 {
  width: calc(58.333333333333336% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-lg-8 {
  width: calc(66.66666666666667% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-lg-9 {
  width: calc(75.0% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-lg-10 {
  width: calc(83.33333333333334% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-lg-11 {
  width: calc(91.66666666666667% - 25.0px);
}

[id="s-3e716cbf-9086-4380-a242-6936dcd0b51c"] > .shg-row > .shg-c-lg-12 {
  width: calc(100.0% - 25.0px);
}

}

#s-4ae33d5e-4188-4023-8673-27066ecdc65f {
  text-align: center;
}




  #s-4ae33d5e-4188-4023-8673-27066ecdc65f img.shogun-image {
    
    width: px;
  }


#s-4ae33d5e-4188-4023-8673-27066ecdc65f .shogun-image-content {
  
    align-items: center;
  
}

#s-1006068b-a32e-4183-aacd-63c38fd8edd4 {
  text-align: center;
}




  #s-1006068b-a32e-4183-aacd-63c38fd8edd4 img.shogun-image {
    
    width: px;
  }


#s-1006068b-a32e-4183-aacd-63c38fd8edd4 .shogun-image-content {
  
    align-items: center;
  
}

#s-da7aff8c-abd7-48ac-aaab-1e050d1f0c54 {
  text-align: center;
}




  #s-da7aff8c-abd7-48ac-aaab-1e050d1f0c54 img.shogun-image {
    
    width: px;
  }


#s-da7aff8c-abd7-48ac-aaab-1e050d1f0c54 .shogun-image-content {
  
    align-items: center;
  
}

#s-0a6547a7-2446-485d-b797-43643641e8f5 {
  text-align: center;
}




  #s-0a6547a7-2446-485d-b797-43643641e8f5 img.shogun-image {
    
    width: px;
  }


#s-0a6547a7-2446-485d-b797-43643641e8f5 .shogun-image-content {
  
    align-items:  flex-start;
  
}

#s-8948d06a-d242-43c0-ad21-696daa57664b {
  text-align: center;
}




  #s-8948d06a-d242-43c0-ad21-696daa57664b img.shogun-image {
    
    width: px;
  }


#s-8948d06a-d242-43c0-ad21-696daa57664b .shogun-image-content {
  
    align-items: center;
  
}

#s-ec1a5bad-0234-4cc0-8937-dde05ec31b17 {
  text-align: center;
}




  #s-ec1a5bad-0234-4cc0-8937-dde05ec31b17 img.shogun-image {
    
    width: px;
  }


#s-ec1a5bad-0234-4cc0-8937-dde05ec31b17 .shogun-image-content {
  
    align-items: center;
  
}

#s-fd105fa0-06f7-478c-a400-442f860b2f20 {
  min-height: 50px;
}
@media (min-width: 768px) and (max-width: 991px){#s-fd105fa0-06f7-478c-a400-442f860b2f20 {
  display: none;
}
#s-fd105fa0-06f7-478c-a400-442f860b2f20, #wrap-s-fd105fa0-06f7-478c-a400-442f860b2f20 { display:none !important; }}@media (max-width: 767px){#s-fd105fa0-06f7-478c-a400-442f860b2f20 {
  display: none;
}
#s-fd105fa0-06f7-478c-a400-442f860b2f20, #wrap-s-fd105fa0-06f7-478c-a400-442f860b2f20 { display:none !important; }}







#s-fd105fa0-06f7-478c-a400-442f860b2f20 > .shg-box-overlay {
  background-color: #fff;
  opacity: 0;
}#s-fd105fa0-06f7-478c-a400-442f860b2f20.shg-box.shg-c {
  justify-content: center;
}

#s-6789f43b-a865-421d-833a-0a58562ec70d {
  min-height: 50px;
}
@media (min-width: 1200px){#s-6789f43b-a865-421d-833a-0a58562ec70d {
  display: none;
}
#s-6789f43b-a865-421d-833a-0a58562ec70d, #wrap-s-6789f43b-a865-421d-833a-0a58562ec70d { display:none !important; }}@media (min-width: 992px) and (max-width: 1199px){#s-6789f43b-a865-421d-833a-0a58562ec70d {
  display: none;
}
#s-6789f43b-a865-421d-833a-0a58562ec70d, #wrap-s-6789f43b-a865-421d-833a-0a58562ec70d { display:none !important; }}







#s-6789f43b-a865-421d-833a-0a58562ec70d > .shg-box-overlay {
  background-color: #fff;
  opacity: 0;
}#s-6789f43b-a865-421d-833a-0a58562ec70d.shg-box.shg-c {
  justify-content: center;
}

#s-5b77c995-f766-4e99-9c21-7ed5e24932bd {
  text-align: center;
}




  #s-5b77c995-f766-4e99-9c21-7ed5e24932bd img.shogun-image {
    
    width: px;
  }


#s-5b77c995-f766-4e99-9c21-7ed5e24932bd .shogun-image-content {
  
    align-items: center;
  
}

#s-6b303208-cef1-4a14-b6d3-d4490509a079 {
  margin-top: 120px;
margin-left: 35px;
margin-bottom: 220px;
margin-right: 35px;
padding-top: 10px;
padding-bottom: 10px;
text-align: left;
}

#s-6b303208-cef1-4a14-b6d3-d4490509a079 .shogun-heading-component h1 {
  color: rgba(255, 255, 255, 1);
  font-weight:   ;
  font-family: Made Canvas;
  font-style:   ;
  font-size: 32px;
  line-height: ;
  letter-spacing: ;
  text-align: left;
}



#s-d00b7e8f-cf4e-4e35-8504-940946daef4e {
  border-style: solid;
margin-top: 30px;
margin-bottom: 55px;
padding-top: 15px;
padding-left: 20px;
padding-bottom: 15px;
padding-right: 20px;
border-top-width: 1px;
border-left-width: 1px;
border-bottom-width: 1px;
border-right-width: 1px;
border-color: #2C2A34;
border-radius: 30px;
background-color: rgba(255, 255, 255, 1);
text-align: center;
text-decoration: none;
background-image: none;
hover-type: color;
color: rgba(0, 0, 0, 1);
}
#s-d00b7e8f-cf4e-4e35-8504-940946daef4e:hover {border-style: solid !important;
border-top-width: 1px !important;
border-left-width: 1px !important;
border-bottom-width: 1px !important;
border-right-width: 1px !important;
border-color: #2C2A34 !important;
border-radius: 30px !important;
background-color: rgba(175, 163, 66, 1) !important;
text-decoration: none !important;
background-image: none !important;
hover-type: color !important;
color: rgba(255, 255, 255, 1) !important;}#s-d00b7e8f-cf4e-4e35-8504-940946daef4e:active {background-color: #000000 !important;
text-decoration: none !important;}@media (min-width: 1200px){#s-d00b7e8f-cf4e-4e35-8504-940946daef4e {
  display: none;
}
#s-d00b7e8f-cf4e-4e35-8504-940946daef4e, #wrap-s-d00b7e8f-cf4e-4e35-8504-940946daef4e { display:none !important; }}@media (min-width: 992px) and (max-width: 1199px){#s-d00b7e8f-cf4e-4e35-8504-940946daef4e {
  display: none;
}
#s-d00b7e8f-cf4e-4e35-8504-940946daef4e, #wrap-s-d00b7e8f-cf4e-4e35-8504-940946daef4e { display:none !important; }}@media (min-width: 768px) and (max-width: 991px){#s-d00b7e8f-cf4e-4e35-8504-940946daef4e {
  
}
}@media (max-width: 767px){#s-d00b7e8f-cf4e-4e35-8504-940946daef4e {
  
}
}


#s-d00b7e8f-cf4e-4e35-8504-940946daef4e.shg-btn {
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  
  
  font-family: futura;
  display:  inline-block ;
}

#s-0c8adea0-f6aa-4c15-a082-1d2c8a529c55 {
  text-align: center;
}
@media (min-width: 768px) and (max-width: 991px){#s-0c8adea0-f6aa-4c15-a082-1d2c8a529c55 {
  display: none;
}
#s-0c8adea0-f6aa-4c15-a082-1d2c8a529c55, #wrap-s-0c8adea0-f6aa-4c15-a082-1d2c8a529c55 { display:none !important; }}@media (max-width: 767px){#s-0c8adea0-f6aa-4c15-a082-1d2c8a529c55 {
  display: none;
}
#s-0c8adea0-f6aa-4c15-a082-1d2c8a529c55, #wrap-s-0c8adea0-f6aa-4c15-a082-1d2c8a529c55 { display:none !important; }}



  #s-0c8adea0-f6aa-4c15-a082-1d2c8a529c55 img.shogun-image {
    
    width: px;
  }


#s-0c8adea0-f6aa-4c15-a082-1d2c8a529c55 .shogun-image-content {
  
    align-items: center;
  
}

#s-e703a463-6d7d-41a6-8e2a-09630011e0e7 {
  border-style: solid;
margin-top: 30px;
margin-bottom: 30px;
padding-top: 15px;
padding-left: 20px;
padding-bottom: 15px;
padding-right: 20px;
border-top-width: 1px;
border-left-width: 1px;
border-bottom-width: 1px;
border-right-width: 1px;
border-color: #2C2A34;
border-radius: 30px;
background-color: rgba(255, 255, 255, 1);
text-align: center;
text-decoration: none;
background-image: none;
hover-type: color;
color: rgba(0, 0, 0, 1);
}
#s-e703a463-6d7d-41a6-8e2a-09630011e0e7:hover {border-style: solid !important;
border-top-width: 1px !important;
border-left-width: 1px !important;
border-bottom-width: 1px !important;
border-right-width: 1px !important;
border-color: #2C2A34 !important;
border-radius: 30px !important;
background-color: rgba(90, 100, 74, 1) !important;
text-decoration: none !important;
background-image: none !important;
hover-type: color !important;
color: rgba(255, 255, 255, 1) !important;}#s-e703a463-6d7d-41a6-8e2a-09630011e0e7:active {background-color: #000000 !important;
text-decoration: none !important;}@media (min-width: 1200px){#s-e703a463-6d7d-41a6-8e2a-09630011e0e7 {
  display: none;
}
#s-e703a463-6d7d-41a6-8e2a-09630011e0e7, #wrap-s-e703a463-6d7d-41a6-8e2a-09630011e0e7 { display:none !important; }}@media (min-width: 992px) and (max-width: 1199px){#s-e703a463-6d7d-41a6-8e2a-09630011e0e7 {
  display: none;
}
#s-e703a463-6d7d-41a6-8e2a-09630011e0e7, #wrap-s-e703a463-6d7d-41a6-8e2a-09630011e0e7 { display:none !important; }}@media (min-width: 768px) and (max-width: 991px){#s-e703a463-6d7d-41a6-8e2a-09630011e0e7 {
  
}
}@media (max-width: 767px){#s-e703a463-6d7d-41a6-8e2a-09630011e0e7 {
  
}
}


#s-e703a463-6d7d-41a6-8e2a-09630011e0e7.shg-btn {
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  
  
  font-family: futura;
  display:  inline-block ;
}

#s-a9eaec76-01e9-4cce-b566-6215291f6eed {
  min-height: 50px;
}
@media (min-width: 1200px){#s-a9eaec76-01e9-4cce-b566-6215291f6eed {
  display: none;
}
#s-a9eaec76-01e9-4cce-b566-6215291f6eed, #wrap-s-a9eaec76-01e9-4cce-b566-6215291f6eed { display:none !important; }}@media (min-width: 992px) and (max-width: 1199px){#s-a9eaec76-01e9-4cce-b566-6215291f6eed {
  display: none;
}
#s-a9eaec76-01e9-4cce-b566-6215291f6eed, #wrap-s-a9eaec76-01e9-4cce-b566-6215291f6eed { display:none !important; }}







#s-a9eaec76-01e9-4cce-b566-6215291f6eed > .shg-box-overlay {
  background-color: #fff;
  opacity: 0;
}#s-a9eaec76-01e9-4cce-b566-6215291f6eed.shg-box.shg-c {
  justify-content: center;
}

#s-cc4195be-b33f-4565-b7f1-dab8dcfa70c3 {
  background-repeat: no-repeat;
background-size: 0px 1%;
min-height: 50px;
background-position: center center;
}
@media (min-width: 1200px){#s-cc4195be-b33f-4565-b7f1-dab8dcfa70c3 {
  display: none;
}
#s-cc4195be-b33f-4565-b7f1-dab8dcfa70c3, #wrap-s-cc4195be-b33f-4565-b7f1-dab8dcfa70c3 { display:none !important; }}@media (min-width: 992px) and (max-width: 1199px){#s-cc4195be-b33f-4565-b7f1-dab8dcfa70c3 {
  display: none;
}
#s-cc4195be-b33f-4565-b7f1-dab8dcfa70c3, #wrap-s-cc4195be-b33f-4565-b7f1-dab8dcfa70c3 { display:none !important; }}







#s-cc4195be-b33f-4565-b7f1-dab8dcfa70c3 > .shg-box-overlay {
  background-color: #fff;
  opacity: 0;
}#s-cc4195be-b33f-4565-b7f1-dab8dcfa70c3.shg-box.shg-c {
  justify-content: center;
}

#s-89c33ecf-2cb7-44f7-8543-4e2efe1d9c6e {
  padding-top: 10px;
padding-bottom: 15px;
text-align: center;
}
@media (min-width: 1200px){#s-89c33ecf-2cb7-44f7-8543-4e2efe1d9c6e {
  display: none;
}
#s-89c33ecf-2cb7-44f7-8543-4e2efe1d9c6e, #wrap-s-89c33ecf-2cb7-44f7-8543-4e2efe1d9c6e { display:none !important; }}@media (min-width: 992px) and (max-width: 1199px){#s-89c33ecf-2cb7-44f7-8543-4e2efe1d9c6e {
  display: none;
}
#s-89c33ecf-2cb7-44f7-8543-4e2efe1d9c6e, #wrap-s-89c33ecf-2cb7-44f7-8543-4e2efe1d9c6e { display:none !important; }}
#s-89c33ecf-2cb7-44f7-8543-4e2efe1d9c6e .shogun-heading-component h2 {
  color: rgba(0, 0, 0, 1);
  font-weight:   ;
  font-family: Made Canvas;
  font-style:   ;
  font-size: 24px;
  line-height: 1.5em;
  letter-spacing: ;
  text-align: center;
}



#s-6431178c-c744-40a0-9ad2-2c3e34b05353 {
  margin-top: 0px;
margin-bottom: 0px;
padding-top: 0px;
padding-bottom: 0px;
}
@media (min-width: 1200px){#s-6431178c-c744-40a0-9ad2-2c3e34b05353 {
  display: none;
}
#s-6431178c-c744-40a0-9ad2-2c3e34b05353, #wrap-s-6431178c-c744-40a0-9ad2-2c3e34b05353 { display:none !important; }}@media (min-width: 992px) and (max-width: 1199px){#s-6431178c-c744-40a0-9ad2-2c3e34b05353 {
  display: none;
}
#s-6431178c-c744-40a0-9ad2-2c3e34b05353, #wrap-s-6431178c-c744-40a0-9ad2-2c3e34b05353 { display:none !important; }}
@media (min-width: 0px) {
[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-xs-1 {
  width: calc(8.333333333333334% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-xs-2 {
  width: calc(16.666666666666668% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-xs-3 {
  width: calc(25.0% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-xs-4 {
  width: calc(33.333333333333336% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-xs-5 {
  width: calc(41.66666666666667% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-xs-6 {
  width: calc(50.0% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-xs-7 {
  width: calc(58.333333333333336% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-xs-8 {
  width: calc(66.66666666666667% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-xs-9 {
  width: calc(75.0% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-xs-10 {
  width: calc(83.33333333333334% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-xs-11 {
  width: calc(91.66666666666667% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-xs-12 {
  width: calc(100.0% - 0.0px);
}

}

@media (min-width: 768px) {
[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-sm-1 {
  width: calc(8.333333333333334% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-sm-2 {
  width: calc(16.666666666666668% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-sm-3 {
  width: calc(25.0% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-sm-4 {
  width: calc(33.333333333333336% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-sm-5 {
  width: calc(41.66666666666667% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-sm-6 {
  width: calc(50.0% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-sm-7 {
  width: calc(58.333333333333336% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-sm-8 {
  width: calc(66.66666666666667% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-sm-9 {
  width: calc(75.0% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-sm-10 {
  width: calc(83.33333333333334% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-sm-11 {
  width: calc(91.66666666666667% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-sm-12 {
  width: calc(100.0% - 0.0px);
}

}

@media (min-width: 992px) {
[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-md-1 {
  width: calc(8.333333333333334% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-md-2 {
  width: calc(16.666666666666668% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-md-3 {
  width: calc(25.0% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-md-4 {
  width: calc(33.333333333333336% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-md-5 {
  width: calc(41.66666666666667% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-md-6 {
  width: calc(50.0% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-md-7 {
  width: calc(58.333333333333336% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-md-8 {
  width: calc(66.66666666666667% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-md-9 {
  width: calc(75.0% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-md-10 {
  width: calc(83.33333333333334% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-md-11 {
  width: calc(91.66666666666667% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-md-12 {
  width: calc(100.0% - 0.0px);
}

}

@media (min-width: 1200px) {
[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-lg-1 {
  width: calc(8.333333333333334% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-lg-2 {
  width: calc(16.666666666666668% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-lg-3 {
  width: calc(25.0% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-lg-4 {
  width: calc(33.333333333333336% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-lg-5 {
  width: calc(41.66666666666667% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-lg-6 {
  width: calc(50.0% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-lg-7 {
  width: calc(58.333333333333336% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-lg-8 {
  width: calc(66.66666666666667% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-lg-9 {
  width: calc(75.0% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-lg-10 {
  width: calc(83.33333333333334% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-lg-11 {
  width: calc(91.66666666666667% - 0.0px);
}

[id="s-6431178c-c744-40a0-9ad2-2c3e34b05353"] > .shg-row > .shg-c-lg-12 {
  width: calc(100.0% - 0.0px);
}

}

#s-19564245-04ae-4958-a3e5-07a982371e2f {
  text-align: center;
}




  #s-19564245-04ae-4958-a3e5-07a982371e2f img.shogun-image {
    
    width: px;
  }


#s-19564245-04ae-4958-a3e5-07a982371e2f .shogun-image-content {
  
    align-items: center;
  
}

#s-8e9e2405-148f-4eb9-b96b-8c697d97e1df {
  text-align: center;
}




  #s-8e9e2405-148f-4eb9-b96b-8c697d97e1df img.shogun-image {
    
    width: px;
  }


#s-8e9e2405-148f-4eb9-b96b-8c697d97e1df .shogun-image-content {
  
    align-items: center;
  
}

#s-28379c7a-cfe2-4cff-a17f-e5dd59e6c62a {
  text-align: center;
}




  #s-28379c7a-cfe2-4cff-a17f-e5dd59e6c62a img.shogun-image {
    
    width: px;
  }


#s-28379c7a-cfe2-4cff-a17f-e5dd59e6c62a .shogun-image-content {
  
    align-items: center;
  
}

#s-e18483ea-e980-4c09-840e-30b255013603 {
  max-width: 100%;
text-align: center;
}
@media (min-width: 1200px){#s-e18483ea-e980-4c09-840e-30b255013603 {
  display: none;
}
#s-e18483ea-e980-4c09-840e-30b255013603, #wrap-s-e18483ea-e980-4c09-840e-30b255013603 { display:none !important; }}@media (min-width: 992px) and (max-width: 1199px){#s-e18483ea-e980-4c09-840e-30b255013603 {
  display: none;
}
#s-e18483ea-e980-4c09-840e-30b255013603, #wrap-s-e18483ea-e980-4c09-840e-30b255013603 { display:none !important; }}



  #s-e18483ea-e980-4c09-840e-30b255013603 img.shogun-image {
    
    width: px;
  }


#s-e18483ea-e980-4c09-840e-30b255013603 .shogun-image-content {
  
    align-items: center;
  
}

#s-4418256c-e580-414f-93f3-ee589ba554b9 {
  border-style: solid;
margin-top: 30px;
margin-bottom: 30px;
padding-top: 15px;
padding-left: 20px;
padding-bottom: 15px;
padding-right: 20px;
border-top-width: 1px;
border-left-width: 1px;
border-bottom-width: 1px;
border-right-width: 1px;
border-color: #2C2A34;
border-radius: 30px;
background-color: rgba(255, 255, 255, 1);
text-align: center;
text-decoration: none;
background-image: none;
hover-type: color;
color: rgba(0, 0, 0, 1);
}
#s-4418256c-e580-414f-93f3-ee589ba554b9:hover {border-style: solid !important;
border-top-width: 1px !important;
border-left-width: 1px !important;
border-bottom-width: 1px !important;
border-right-width: 1px !important;
border-color: #2C2A34 !important;
border-radius: 30px !important;
background-color: rgba(175, 163, 66, 1) !important;
text-decoration: none !important;
background-image: none !important;
hover-type: color !important;
color: rgba(255, 255, 255, 1) !important;}#s-4418256c-e580-414f-93f3-ee589ba554b9:active {background-color: #000000 !important;
text-decoration: none !important;}@media (min-width: 1200px){#s-4418256c-e580-414f-93f3-ee589ba554b9 {
  display: none;
}
#s-4418256c-e580-414f-93f3-ee589ba554b9, #wrap-s-4418256c-e580-414f-93f3-ee589ba554b9 { display:none !important; }}@media (min-width: 992px) and (max-width: 1199px){#s-4418256c-e580-414f-93f3-ee589ba554b9 {
  display: none;
}
#s-4418256c-e580-414f-93f3-ee589ba554b9, #wrap-s-4418256c-e580-414f-93f3-ee589ba554b9 { display:none !important; }}@media (min-width: 768px) and (max-width: 991px){#s-4418256c-e580-414f-93f3-ee589ba554b9 {
  
}
}@media (max-width: 767px){#s-4418256c-e580-414f-93f3-ee589ba554b9 {
  
}
}


#s-4418256c-e580-414f-93f3-ee589ba554b9.shg-btn {
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  
  
  font-family: futura;
  display:  inline-block ;
}

#s-a7fe4016-0370-4001-b90c-283420d4dab1 {
  margin-top: 15px;
margin-bottom: 15px;
}
@media (min-width: 1200px){#s-a7fe4016-0370-4001-b90c-283420d4dab1 {
  display: none;
}
#s-a7fe4016-0370-4001-b90c-283420d4dab1, #wrap-s-a7fe4016-0370-4001-b90c-283420d4dab1 { display:none !important; }}@media (min-width: 992px) and (max-width: 1199px){#s-a7fe4016-0370-4001-b90c-283420d4dab1 {
  display: none;
}
#s-a7fe4016-0370-4001-b90c-283420d4dab1, #wrap-s-a7fe4016-0370-4001-b90c-283420d4dab1 { display:none !important; }}
@media (min-width: 0px) {
[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-xs-12 {
  width: 100%;
}

}

@media (min-width: 768px) {
[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-sm-1 {
  width: calc(8.333333333333334% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-sm-2 {
  width: calc(16.666666666666668% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-sm-3 {
  width: calc(25.0% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-sm-4 {
  width: calc(33.333333333333336% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-sm-5 {
  width: calc(41.66666666666667% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-sm-6 {
  width: calc(50.0% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-sm-7 {
  width: calc(58.333333333333336% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-sm-8 {
  width: calc(66.66666666666667% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-sm-9 {
  width: calc(75.0% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-sm-10 {
  width: calc(83.33333333333334% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-sm-11 {
  width: calc(91.66666666666667% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-sm-12 {
  width: calc(100.0% - 15.0px);
}

}

@media (min-width: 992px) {
[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-md-1 {
  width: calc(8.333333333333334% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-md-2 {
  width: calc(16.666666666666668% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-md-3 {
  width: calc(25.0% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-md-4 {
  width: calc(33.333333333333336% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-md-5 {
  width: calc(41.66666666666667% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-md-6 {
  width: calc(50.0% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-md-7 {
  width: calc(58.333333333333336% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-md-8 {
  width: calc(66.66666666666667% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-md-9 {
  width: calc(75.0% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-md-10 {
  width: calc(83.33333333333334% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-md-11 {
  width: calc(91.66666666666667% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-md-12 {
  width: calc(100.0% - 15.0px);
}

}

@media (min-width: 1200px) {
[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-lg-1 {
  width: calc(8.333333333333334% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-lg-2 {
  width: calc(16.666666666666668% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-lg-3 {
  width: calc(25.0% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-lg-4 {
  width: calc(33.333333333333336% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-lg-5 {
  width: calc(41.66666666666667% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-lg-6 {
  width: calc(50.0% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-lg-7 {
  width: calc(58.333333333333336% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-lg-8 {
  width: calc(66.66666666666667% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-lg-9 {
  width: calc(75.0% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-lg-10 {
  width: calc(83.33333333333334% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-lg-11 {
  width: calc(91.66666666666667% - 15.0px);
}

[id="s-e4caf4f3-c10d-47b8-9daa-ae5fb16f5150"] > .shg-row > .shg-c-lg-12 {
  width: calc(100.0% - 15.0px);
}

}

.shogun-table-wrapper {
  overflow: auto;
}

table.shogun-table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-style: hidden;
}

thead.shogun-table-column-container {
  font-weight: bold;
}

td.shogun-table-column {
  padding: 10px;
}

td.shogun-table-row {
  padding: 10px;
  min-height: 45px;
}

td.shogun-table-column:only-child, td.shogun-table-row:only-child {
  width: 100%;
}

#s-1794ab1a-b4b3-49a4-99f6-68f3e289150e {
  margin-left: 0%;
margin-right: 0%;
max-width: 100%;
}

#s-1794ab1a-b4b3-49a4-99f6-68f3e289150e .shogun-table-wrapper {
  overflow: auto;
  border: 0px solid rgba(255, 255, 255, 1);
  border-radius: 0px;
}

#s-1794ab1a-b4b3-49a4-99f6-68f3e289150e table.shogun-table {
  margin: 0;
  padding: 0;
}

#s-1794ab1a-b4b3-49a4-99f6-68f3e289150e td.shogun-table-column {
  background-color: #fff;
  padding: 10px;
  text-align: inherit;
}

#s-1794ab1a-b4b3-49a4-99f6-68f3e289150e td.shogun-table-row {
  background-color: #fff;
  padding: 10px;
}

#s-1794ab1a-b4b3-49a4-99f6-68f3e289150e td.shogun-table-column, #s-1794ab1a-b4b3-49a4-99f6-68f3e289150e td.shogun-table-row {
  border: 0px solid rgba(255, 255, 255, 1);
}

#s-67a75abf-86ac-4eed-a16a-bc48fa348296 {
  text-align: center;
}




  #s-67a75abf-86ac-4eed-a16a-bc48fa348296 img.shogun-image {
    
    width: px;
  }


#s-67a75abf-86ac-4eed-a16a-bc48fa348296 .shogun-image-content {
  
    align-items: center;
  
}

#s-fccac420-5268-4533-883d-bf56fe4586bb {
  text-align: center;
}




  #s-fccac420-5268-4533-883d-bf56fe4586bb img.shogun-image {
    
    width: px;
  }


#s-fccac420-5268-4533-883d-bf56fe4586bb .shogun-image-content {
  
    align-items: center;
  
}

#s-478bc17d-ce4b-43c9-8a43-4440eeedb3f6 {
  text-align: center;
}




  #s-478bc17d-ce4b-43c9-8a43-4440eeedb3f6 img.shogun-image {
    
    width: px;
  }


#s-478bc17d-ce4b-43c9-8a43-4440eeedb3f6 .shogun-image-content {
  
    align-items: center;
  
}

#s-a60bad57-5d87-4a5e-9ee8-51401ecaeec4 {
  text-align: center;
}




  #s-a60bad57-5d87-4a5e-9ee8-51401ecaeec4 img.shogun-image {
    
    width: px;
  }


#s-a60bad57-5d87-4a5e-9ee8-51401ecaeec4 .shogun-image-content {
  
    align-items: center;
  
}

#s-e2d8e313-1903-4576-8c22-e467a515eeb9 {
  text-align: center;
}




  #s-e2d8e313-1903-4576-8c22-e467a515eeb9 img.shogun-image {
    
    width: px;
  }


#s-e2d8e313-1903-4576-8c22-e467a515eeb9 .shogun-image-content {
  
    align-items: center;
  
}

#s-7b926661-a67f-4f5f-b83f-185cf83a37fa {
  text-align: center;
}




  #s-7b926661-a67f-4f5f-b83f-185cf83a37fa img.shogun-image {
    
    width: px;
  }


#s-7b926661-a67f-4f5f-b83f-185cf83a37fa .shogun-image-content {
  
    align-items: center;
  
}

#s-1b5d5346-4cc3-4bd5-b83d-1c4cff1d97b7 {
  min-height: 50px;
}
@media (min-width: 1200px){#s-1b5d5346-4cc3-4bd5-b83d-1c4cff1d97b7 {
  
}
}@media (min-width: 992px) and (max-width: 1199px){#s-1b5d5346-4cc3-4bd5-b83d-1c4cff1d97b7 {
  
}
}







#s-1b5d5346-4cc3-4bd5-b83d-1c4cff1d97b7 > .shg-box-overlay {
  background-color: #fff;
  opacity: 0;
}#s-1b5d5346-4cc3-4bd5-b83d-1c4cff1d97b7.shg-box.shg-c {
  justify-content: center;
}

#s-0a44dd27-466b-4600-9786-ec135ebe833a {
  min-height: 50px;
}








#s-0a44dd27-466b-4600-9786-ec135ebe833a > .shg-box-overlay {
  background-color: #fff;
  opacity: 0;
}#s-0a44dd27-466b-4600-9786-ec135ebe833a.shg-box.shg-c {
  justify-content: center;
}

@media (min-width: 0px) {
[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-xs-12 {
  width: 100%;
}

}

@media (min-width: 768px) {
[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-sm-1 {
  width: calc(8.333333333333334% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-sm-2 {
  width: calc(16.666666666666668% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-sm-3 {
  width: calc(25.0% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-sm-4 {
  width: calc(33.333333333333336% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-sm-5 {
  width: calc(41.66666666666667% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-sm-6 {
  width: calc(50.0% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-sm-7 {
  width: calc(58.333333333333336% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-sm-8 {
  width: calc(66.66666666666667% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-sm-9 {
  width: calc(75.0% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-sm-10 {
  width: calc(83.33333333333334% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-sm-11 {
  width: calc(91.66666666666667% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-sm-12 {
  width: calc(100.0% - 3.3333333333333335px);
}

}

@media (min-width: 992px) {
[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-md-1 {
  width: calc(8.333333333333334% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-md-2 {
  width: calc(16.666666666666668% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-md-3 {
  width: calc(25.0% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-md-4 {
  width: calc(33.333333333333336% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-md-5 {
  width: calc(41.66666666666667% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-md-6 {
  width: calc(50.0% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-md-7 {
  width: calc(58.333333333333336% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-md-8 {
  width: calc(66.66666666666667% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-md-9 {
  width: calc(75.0% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-md-10 {
  width: calc(83.33333333333334% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-md-11 {
  width: calc(91.66666666666667% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-md-12 {
  width: calc(100.0% - 3.3333333333333335px);
}

}

@media (min-width: 1200px) {
[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-lg-1 {
  width: calc(8.333333333333334% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-lg-2 {
  width: calc(16.666666666666668% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-lg-3 {
  width: calc(25.0% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-lg-4 {
  width: calc(33.333333333333336% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-lg-5 {
  width: calc(41.66666666666667% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-lg-6 {
  width: calc(50.0% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-lg-7 {
  width: calc(58.333333333333336% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-lg-8 {
  width: calc(66.66666666666667% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-lg-9 {
  width: calc(75.0% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-lg-10 {
  width: calc(83.33333333333334% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-lg-11 {
  width: calc(91.66666666666667% - 3.3333333333333335px);
}

[id="s-996cf8b6-fc65-488e-b5f8-3b22a01f145d"] > .shg-row > .shg-c-lg-12 {
  width: calc(100.0% - 3.3333333333333335px);
}

}

#s-9dee6616-2e0d-48ca-8029-598c320361f6 {
  max-width: 100%;
text-align: center;
}




  #s-9dee6616-2e0d-48ca-8029-598c320361f6 img.shogun-image {
    
    width: px;
  }


#s-9dee6616-2e0d-48ca-8029-598c320361f6 .shogun-image-content {
  
    align-items: center;
  
}

#s-8d0a055d-e861-4551-b087-ec0490ab8553 {
  padding-top: 10px;
padding-bottom: 15px;
text-align: center;
}

#s-8d0a055d-e861-4551-b087-ec0490ab8553 .shogun-heading-component h2 {
  color: rgba(255, 255, 255, 1);
  font-weight:   ;
  font-family: ;
  font-style:   ;
  font-size: 24px;
  line-height: 1em;
  letter-spacing: 2px;
  text-align: center;
}



#s-9918c42b-ba38-4e1b-a549-dcb5bc315c4b {
  max-width: 100%;
text-align: center;
}




  #s-9918c42b-ba38-4e1b-a549-dcb5bc315c4b img.shogun-image {
    
    width: px;
  }


#s-9918c42b-ba38-4e1b-a549-dcb5bc315c4b .shogun-image-content {
  
    align-items: center;
  
}

#s-cca9df72-91f5-44c4-b2cc-e180d2860c14 {
  padding-top: 10px;
padding-bottom: 15px;
text-align: center;
}

#s-cca9df72-91f5-44c4-b2cc-e180d2860c14 .shogun-heading-component h2 {
  color: rgba(255, 255, 255, 1);
  font-weight:   ;
  font-family: ;
  font-style:   ;
  font-size: 24px;
  line-height: 1em;
  letter-spacing: 2px;
  text-align: center;
}



#s-edf17865-03d3-4c9e-a1ba-fc8a4f2882e7 {
  max-width: 100%;
text-align: center;
}




  #s-edf17865-03d3-4c9e-a1ba-fc8a4f2882e7 img.shogun-image {
    
    width: px;
  }


#s-edf17865-03d3-4c9e-a1ba-fc8a4f2882e7 .shogun-image-content {
  
    align-items: center;
  
}

#s-ea3884cf-a91d-4e67-8f5f-b5c5aa73ea3a {
  padding-top: 10px;
padding-bottom: 15px;
text-align: center;
}

#s-ea3884cf-a91d-4e67-8f5f-b5c5aa73ea3a .shogun-heading-component h2 {
  color: rgba(255, 255, 255, 1);
  font-weight:   ;
  font-family: ;
  font-style:   ;
  font-size: 24px;
  line-height: 1em;
  letter-spacing: 2px;
  text-align: center;
}



#s-e664d676-4bfa-44e2-a6fb-c970208f870e {
  background-color: rgba(250, 244, 237, 1);
}
@media (min-width: 768px) and (max-width: 991px){#s-e664d676-4bfa-44e2-a6fb-c970208f870e {
  display: none;
}
#s-e664d676-4bfa-44e2-a6fb-c970208f870e, #wrap-s-e664d676-4bfa-44e2-a6fb-c970208f870e { display:none !important; }}@media (max-width: 767px){#s-e664d676-4bfa-44e2-a6fb-c970208f870e {
  display: none;
}
#s-e664d676-4bfa-44e2-a6fb-c970208f870e, #wrap-s-e664d676-4bfa-44e2-a6fb-c970208f870e { display:none !important; }}
@media (min-width: 0px) {
[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-xs-12 {
  width: 100%;
}

}

@media (min-width: 768px) {
[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-sm-1 {
  width: calc(8.333333333333334% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-sm-2 {
  width: calc(16.666666666666668% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-sm-3 {
  width: calc(25.0% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-sm-4 {
  width: calc(33.333333333333336% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-sm-5 {
  width: calc(41.66666666666667% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-sm-6 {
  width: calc(50.0% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-sm-7 {
  width: calc(58.333333333333336% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-sm-8 {
  width: calc(66.66666666666667% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-sm-9 {
  width: calc(75.0% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-sm-10 {
  width: calc(83.33333333333334% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-sm-11 {
  width: calc(91.66666666666667% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-sm-12 {
  width: calc(100.0% - 0.0px);
}

}

@media (min-width: 992px) {
[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-md-1 {
  width: calc(8.333333333333334% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-md-2 {
  width: calc(16.666666666666668% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-md-3 {
  width: calc(25.0% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-md-4 {
  width: calc(33.333333333333336% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-md-5 {
  width: calc(41.66666666666667% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-md-6 {
  width: calc(50.0% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-md-7 {
  width: calc(58.333333333333336% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-md-8 {
  width: calc(66.66666666666667% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-md-9 {
  width: calc(75.0% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-md-10 {
  width: calc(83.33333333333334% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-md-11 {
  width: calc(91.66666666666667% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-md-12 {
  width: calc(100.0% - 0.0px);
}

}

@media (min-width: 1200px) {
[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-lg-1 {
  width: calc(8.333333333333334% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-lg-2 {
  width: calc(16.666666666666668% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-lg-3 {
  width: calc(25.0% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-lg-4 {
  width: calc(33.333333333333336% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-lg-5 {
  width: calc(41.66666666666667% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-lg-6 {
  width: calc(50.0% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-lg-7 {
  width: calc(58.333333333333336% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-lg-8 {
  width: calc(66.66666666666667% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-lg-9 {
  width: calc(75.0% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-lg-10 {
  width: calc(83.33333333333334% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-lg-11 {
  width: calc(91.66666666666667% - 0.0px);
}

[id="s-e664d676-4bfa-44e2-a6fb-c970208f870e"] > .shg-row > .shg-c-lg-12 {
  width: calc(100.0% - 0.0px);
}

}

#s-56843ddc-0857-4718-b061-127a55e23e48 {
  min-height: 50px;
}








#s-56843ddc-0857-4718-b061-127a55e23e48 > .shg-box-overlay {
  background-color: #fff;
  opacity: 0;
}#s-56843ddc-0857-4718-b061-127a55e23e48.shg-box.shg-c {
  justify-content: center;
}

#s-6c08e389-934f-411e-af9f-964c98108356 {
  margin-left: 85px;
margin-right: 85px;
padding-top: 10px;
padding-bottom: 10px;
text-align: center;
}
@media (min-width: 768px) and (max-width: 991px){#s-6c08e389-934f-411e-af9f-964c98108356 {
  display: none;
}
#s-6c08e389-934f-411e-af9f-964c98108356, #wrap-s-6c08e389-934f-411e-af9f-964c98108356 { display:none !important; }}@media (max-width: 767px){#s-6c08e389-934f-411e-af9f-964c98108356 {
  display: none;
}
#s-6c08e389-934f-411e-af9f-964c98108356, #wrap-s-6c08e389-934f-411e-af9f-964c98108356 { display:none !important; }}
#s-6c08e389-934f-411e-af9f-964c98108356 .shogun-heading-component h2 {
  color: rgba(0, 0, 0, 1);
  font-weight:   ;
  font-family: Made Canvas;
  font-style:   ;
  font-size: 32px;
  line-height: ;
  letter-spacing: ;
  text-align: center;
}



#s-c418faf5-113e-4e2e-a88b-dab4d54a5525 {
  margin-left: 85px;
margin-right: 85px;
}
@media (min-width: 768px) and (max-width: 991px){#s-c418faf5-113e-4e2e-a88b-dab4d54a5525 {
  display: none;
}
#s-c418faf5-113e-4e2e-a88b-dab4d54a5525, #wrap-s-c418faf5-113e-4e2e-a88b-dab4d54a5525 { display:none !important; }}@media (max-width: 767px){#s-c418faf5-113e-4e2e-a88b-dab4d54a5525 {
  display: none;
}
#s-c418faf5-113e-4e2e-a88b-dab4d54a5525, #wrap-s-c418faf5-113e-4e2e-a88b-dab4d54a5525 { display:none !important; }}
#s-e5390386-e899-4014-a2ae-7e374dbd0ede {
  border-style: solid;
margin-top: 30px;
margin-bottom: 30px;
padding-top: 15px;
padding-left: 20px;
padding-bottom: 15px;
padding-right: 20px;
border-top-width: 1px;
border-left-width: 1px;
border-bottom-width: 1px;
border-right-width: 1px;
border-color: #2C2A34;
border-radius: 30px;
background-color: rgba(175, 163, 66, 1);
text-align: center;
text-decoration: none;
background-image: none;
hover-type: color;
color: rgba(255, 255, 255, 1);
}
#s-e5390386-e899-4014-a2ae-7e374dbd0ede:hover {border-style: solid !important;
border-top-width: 1px !important;
border-left-width: 1px !important;
border-bottom-width: 1px !important;
border-right-width: 1px !important;
border-color: #2C2A34 !important;
border-radius: 30px !important;
background-color: rgba(90, 100, 74, 1) !important;
text-decoration: none !important;
background-image: none !important;
hover-type: color !important;
color: rgba(255, 255, 255, 1) !important;}#s-e5390386-e899-4014-a2ae-7e374dbd0ede:active {background-color: #000000 !important;
text-decoration: none !important;}@media (min-width: 768px) and (max-width: 991px){#s-e5390386-e899-4014-a2ae-7e374dbd0ede {
  display: none;
}
#s-e5390386-e899-4014-a2ae-7e374dbd0ede, #wrap-s-e5390386-e899-4014-a2ae-7e374dbd0ede { display:none !important; }}@media (max-width: 767px){#s-e5390386-e899-4014-a2ae-7e374dbd0ede {
  display: none;
}
#s-e5390386-e899-4014-a2ae-7e374dbd0ede, #wrap-s-e5390386-e899-4014-a2ae-7e374dbd0ede { display:none !important; }}


#s-e5390386-e899-4014-a2ae-7e374dbd0ede.shg-btn {
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  
  
  font-family: futura;
  display:  inline-block ;
}

#s-0f5bff47-58db-4b90-889b-2659762d3058 {
  text-align: center;
}




  #s-0f5bff47-58db-4b90-889b-2659762d3058 img.shogun-image {
    
    width: px;
  }


#s-0f5bff47-58db-4b90-889b-2659762d3058 .shogun-image-content {
  
    align-items: center;
  
}

#s-1d266b73-e5e0-48c1-92aa-0ff54a34835b {
  margin-top: -25px;
margin-bottom: -25px;
}








#s-1d266b73-e5e0-48c1-92aa-0ff54a34835b > .shg-box-overlay {
  background-color: #fff;
  opacity: 0;
}#s-1d266b73-e5e0-48c1-92aa-0ff54a34835b.shg-box.shg-c {
  justify-content: center;
}

#s-a7cb677d-7e62-4814-bab7-a145a3ea35e2 {
  box-shadow:0px 0px 0px 0px ;
margin-top: 30px;
margin-bottom: 30px;
min-height: 200px;
background-color: rgba(255, 255, 255, 1);
}








#s-a7cb677d-7e62-4814-bab7-a145a3ea35e2 > .shg-box-overlay {
  background-color: #fff;
  opacity: 0;
}#s-a7cb677d-7e62-4814-bab7-a145a3ea35e2.shg-box.shg-c {
  justify-content: center;
}

#s-e50cf80f-44aa-4b54-8378-e2bb13fe7ae6 {
  border-style: solid;
margin-top: 30px;
margin-bottom: 30px;
padding-top: 15px;
padding-left: 20px;
padding-bottom: 15px;
padding-right: 20px;
border-top-width: 1px;
border-left-width: 1px;
border-bottom-width: 1px;
border-right-width: 1px;
border-color: #2C2A34;
border-radius: 30px;
background-color: rgba(175, 163, 66, 1);
text-align: center;
text-decoration: none;
background-image: none;
hover-type: color;
color: rgba(255, 255, 255, 1);
}
#s-e50cf80f-44aa-4b54-8378-e2bb13fe7ae6:hover {border-style: solid !important;
border-top-width: 1px !important;
border-left-width: 1px !important;
border-bottom-width: 1px !important;
border-right-width: 1px !important;
border-color: #2C2A34 !important;
border-radius: 30px !important;
background-color: rgba(90, 100, 74, 1) !important;
text-decoration: none !important;
background-image: none !important;
hover-type: color !important;
color: rgba(255, 255, 255, 1) !important;}#s-e50cf80f-44aa-4b54-8378-e2bb13fe7ae6:active {background-color: #000000 !important;
text-decoration: none !important;}@media (min-width: 768px) and (max-width: 991px){#s-e50cf80f-44aa-4b54-8378-e2bb13fe7ae6 {
  display: none;
}
#s-e50cf80f-44aa-4b54-8378-e2bb13fe7ae6, #wrap-s-e50cf80f-44aa-4b54-8378-e2bb13fe7ae6 { display:none !important; }}@media (max-width: 767px){#s-e50cf80f-44aa-4b54-8378-e2bb13fe7ae6 {
  display: none;
}
#s-e50cf80f-44aa-4b54-8378-e2bb13fe7ae6, #wrap-s-e50cf80f-44aa-4b54-8378-e2bb13fe7ae6 { display:none !important; }}


#s-e50cf80f-44aa-4b54-8378-e2bb13fe7ae6.shg-btn {
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  
  
  font-family: futura;
  display:  inline-block ;
}

#s-a01fc242-8bf7-4ba2-9445-9a9229b24a62 {
  min-height: 50px;
}
@media (min-width: 1200px){#s-a01fc242-8bf7-4ba2-9445-9a9229b24a62 {
  display: none;
}
#s-a01fc242-8bf7-4ba2-9445-9a9229b24a62, #wrap-s-a01fc242-8bf7-4ba2-9445-9a9229b24a62 { display:none !important; }}@media (min-width: 992px) and (max-width: 1199px){#s-a01fc242-8bf7-4ba2-9445-9a9229b24a62 {
  display: none;
}
#s-a01fc242-8bf7-4ba2-9445-9a9229b24a62, #wrap-s-a01fc242-8bf7-4ba2-9445-9a9229b24a62 { display:none !important; }}







#s-a01fc242-8bf7-4ba2-9445-9a9229b24a62 > .shg-box-overlay {
  background-color: #fff;
  opacity: 0;
}#s-a01fc242-8bf7-4ba2-9445-9a9229b24a62.shg-box.shg-c {
  justify-content: center;
}

#s-1542b5ec-adf6-4d8d-8f29-c5f8355c2688 {
  margin-top: 15px;
margin-bottom: 15px;
}

.shg-social-alignable {
  display: inline-block;
}

.shg-social-container {
  display: flex;
}

.shg-social-element {
  display: inline-block;
  border: none !important;
  padding: 0px !important;
}

#s-28a1f091-442b-418d-bfad-83d923fe6f3f {
  box-shadow:0px 0px 0px 0px ;
border-style: solid;
margin-top: 45px;
margin-bottom: 45px;
border-color: rgba(255, 255, 255, 1);
border-style: solid;
text-align: center;
background-color: rgba(255, 255, 255, 1);
}

/*
  $vgutter : 20px
  $hgutter : 10px;
*/

.shg-c:before,
.shg-c:after {
  content: " ";
  display: table;
}

/**
  Ref:
  https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
  https://dnf.slack.com/archives/C0514HB79/p1538741509000100
**/
.shogun-root {
  z-index: 1;
  position: relative;
  isolation: isolate;
}

.shogun-root iframe {
  display: initial;
}

@media (max-width: 1024px) {
  .shg-c,
  .shg-box {
    background-attachment: scroll !important;
  }
}

@media only screen
  and (min-width: 1024px) and (max-height: 1366px)
  and (-webkit-min-device-pixel-ratio: 1.5) and (hover: none)
  and (orientation: landscape) {
  .shg-box {
    background-attachment: scroll !important;
  }
}

#mc_embed_signup .clear {
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
}

.shg-clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.shogun-image {
  max-width: 100%;
  min-height: inherit;
  max-height: inherit;
  display: inline !important;
  border: 0;
  vertical-align: middle;
}

.shg-fw {
  margin-left: calc(50% - 50vw);
  width: 100vw;
}

.shg-fw .shg-fw {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

div[data-shg-lightbox-switch] {
  cursor: pointer;
}

.shg-lightbox {
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.85);
}

.shg-lightbox.hidden {
  display: none !important;
}

.shg-lightbox .shg-lightbox-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  text-align: center;
}

.shg-lightbox .shg-lightbox-close {
  position: absolute;
  right: 0;
  padding: 5px 0;
  color: #fff;
  font-size: 45px;
  margin-right: 10px;
  line-height: 30px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  z-index: 1;
}

.shg-lightbox .shg-lightbox-image-container {
  padding: 25px;
}

.shg-lightbox .shg-lightbox-image {
  margin: auto;
  max-height: 90vh;
  max-width: 100%;
}

.shg-lightbox .shg-lightbox-close:hover,
.shg-lightbox .shg-lightbox-close:focus {
  color: #a2a2a2;
  text-decoration: none;
  cursor: pointer;
}

.shg-lightbox .shg-lightbox-nav {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: 35px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translate(0%, -50%);
  z-index: 1;
}

.shg-lightbox .shg-lightbox-nav.hidden {
  display: none !important;
}

.shg-lightbox .shg-lightbox-nav.shg-nav-left {
  left: 0;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHdpZHRoPSIxNzkyIiBoZWlnaHQ9IjE3OTIiIHZpZXdCb3g9IjAgMCAxNzkyIDE3OTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbD0id2hpdGUiPjxwYXRoIGQ9Ik0xMjAzIDU0NHEwIDEzLTEwIDIzbC0zOTMgMzkzIDM5MyAzOTNxMTAgMTAgMTAgMjN0LTEwIDIzbC01MCA1MHEtMTAgMTAtMjMgMTB0LTIzLTEwbC00NjYtNDY2cS0xMC0xMC0xMC0yM3QxMC0yM2w0NjYtNDY2cTEwLTEwIDIzLTEwdDIzIDEwbDUwIDUwcTEwIDEwIDEwIDIzeiIvPjwvc3ZnPg==);
}

.shg-lightbox .shg-lightbox-nav.shg-nav-right {
  right: 0;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHdpZHRoPSIxNzkyIiBoZWlnaHQ9IjE3OTIiIHZpZXdCb3g9IjAgMCAxNzkyIDE3OTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbD0id2hpdGUiPjxwYXRoIGQ9Ik0xMTcxIDk2MHEwIDEzLTEwIDIzbC00NjYgNDY2cS0xMCAxMC0yMyAxMHQtMjMtMTBsLTUwLTUwcS0xMC0xMC0xMC0yM3QxMC0yM2wzOTMtMzkzLTM5My0zOTNxLTEwLTEwLTEwLTIzdDEwLTIzbDUwLTUwcTEwLTEwIDIzLTEwdDIzIDEwbDQ2NiA0NjZxMTAgMTAgMTAgMjN6Ii8+PC9zdmc+);
}

@media screen and (min-width: 769px) {
  .shg-lightbox .shg-lightbox-image-container {
    padding: 50px;
  }
}

.shogun-lazyload:not([src]),
.shogun-lazyloading:not([src]) {
	opacity: 0;
}
.shogun-lazyloaded {
	opacity: 1;
	transition: opacity 300ms;
}
